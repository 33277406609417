html,
body,
#root {
  width: 100vw;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: rgb(73, 72, 72);
  font-family: sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
}

.spacestagram-title {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.3em;
}

.attribution {
  font-size: 0.8rem;
  text-align: center;
  color: #666;
  padding: 0 1rem;
}

.github-corner {
  filter: invert(1) contrast(3);
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 1.5rem 0.8rem 4rem 0.8rem;
  flex-grow: 1;
  flex-shrink: 1;
}

@media screen and (max-width: 800px) {
  .card-wrapper {
    grid-template-columns: 1fr;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0.6rem;
  max-width: 300px;
  text-align: center;
  font-family: arial;
  width: 100%;
  border-radius: 12px;
  transition: 0.1s transform ease-out;
}

.card:hover {
  transform: scale(1.02);
}

.media {
  width: 100%;
  height: 220px;
  margin: auto;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 0;
}

.picture-of-the-day-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  height: 2em;
  margin: 0.5rem;
}

.like-and-date-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.like-button {
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 0;
}

.like-button:hover {
  background-color: rgba(249, 24, 128, 0.1);
  border-radius: 50%;
  color: rgb(237, 73, 86);
}

.like-button svg {
  margin-bottom: -2px;
}

.date-of-capture {
  font-size: 12px;
  color: #666;
  margin-top: 0.5rem;
}

.load-more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  border-radius: 18px;
  background-color: rgb(112 108 159);
  color: #fff;
  padding: 0.7rem 1.3rem;
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.load-more-button:hover {
  background-color: rgb(146 141 205);
  color: #fff;
}
